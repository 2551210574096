<template>
    <div>
        <span
            class="tw-mr-6"
            :class="props.textColor"
        >
            <a :href="props.aktion.url">
                <FontAwesomeIcon
                    :icon="icon.icon"
                    class="tw-h-4 tw-mr-0.5"
                    :class="icon.farbe"
                />
                {{ aktion.text }}
            </a>
        </span>
    </div>
</template>

<script setup lang="ts">
import type {InsightAktion} from "../../models/api/insight/InsightAktion";
import {iconAktionTypeMapper} from "../../utilities/Insight/IconMapper";

const props = defineProps<{
    aktion: InsightAktion;
    textColor: string;
}>();
const icon = iconAktionTypeMapper(props.aktion.type);
</script>
