<template>
    <div
        class="tw-text-black tw-block tw-py-4 tw-font-medium tw-w-full"
        @mouseover="showLoeschen = true"
        @mouseleave="showLoeschen = false"
    >
        <div class="tw-flex tw-px-3 tw-min-h-20">
            <div class="tw-flex-none tw-pt-1 tw-relative">
                <span
                    v-if="!benachrichtigung.gelesen"
                    class="tw-absolute tw-top-1 tw-left-0 tw-block tw-h-4 tw-w-4 tw-rounded-full"
                    style="background-color: #00c3d0;"
                />
                <div
                    class="tw-flex tw-justify-center tw-items-center tw-ml-5 tw-h-16 tw-w-16 tw-rounded-full"
                    :style="iconBgColor + benachrichtigung.iconBgColor"
                >
                    <img
                        class="tw-h-8 tw-w-8"
                        aria-hidden="true"
                        :title="benachrichtigung.iconTitel"
                        :src="iconPfad + benachrichtigung.iconBezeichnung"
                        alt=""
                    >
                </div>
            </div>
            <div class="tw-flex-auto tw-ml-6 tw-mt-2">
                <div class="tw-font-bold">
                    {{ benachrichtigung.title }}
                </div>
                <div class="tw-cursor-pointer">
                    <span
                        :title="!benachrichtigung.gelesen
                            ? 'Benachrichtigung als gelesen markieren'
                            : 'Benachrichtigung als ungelesen markieren'"
                        @click="onClickBenachrichtigung"
                    >
                        {{ benachrichtigung.content }}
                    </span>
                    <form
                        v-if="aktionUrl.length > 0"
                        id="form_aktion"
                        ref="aktionForm"
                        class="tw-inline-block"
                        :action="aktionUrl"
                        method="post"
                    >
                        <a
                            v-if="benachrichtigung.aktionText !== null"
                            class="tw-cursor-pointer tw-text-gray-700 tw-underline tw-ml-2"
                            @click="formSubmit"
                        >
                            {{ benachrichtigung.aktionText }} >
                        </a>
                        <input
                            v-for="[key, value] in props.benachrichtigung.aktionData.entries()"
                            :key="key"
                            type="hidden"
                            :name="'data[' + key + ']'"
                            :value="value"
                        >
                    </form>
                </div>
                <div class="tw-text-xs tw-text-gray-400 tw-mt-2">
                    {{ benachrichtigung.erstelltAm }}
                </div>
            </div>
            <div class="tw-flex-none tw-flex tw-items-end tw-justify-end tw-w-10">
                <LoadingSpinner
                    v-show="deleting"
                    class="tw-mr-2"
                />
                <TrashButton
                    v-show="showLoeschen && !deleting"
                    @click="onClickDelete"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type {BenachrichtigungenViewModel} from "../benachrichtigungen/BenachrichtigungenViewModel";
import LoadingSpinner from "../common/LoadingSpinner.vue";
import type {Ref} from "vue";
import TrashButton from "../common/TrashButton.vue";
import {ref} from "vue";

const props = defineProps<{
    benachrichtigung: BenachrichtigungenViewModel;
    benachrichtigungLoeschenParent: (benachrichtigungenId: string) => void;
    benachrichtigungGelesenUngelesen: (benachrichtigung: BenachrichtigungenViewModel) => void;
}>();

const aktionForm = ref<HTMLFormElement | null>(null);

const iconPfad: string = "/static/content/pics/fontawesome/";
const iconBgColor: string = "background-color: ";
const showLoeschen: Ref<boolean> = ref(false);
const deleting: Ref<boolean> = ref(false);
let aktionUrl: string = "";

const onClickDelete = (): void => {
    deleting.value = true;
    props.benachrichtigungLoeschenParent(props.benachrichtigung.id);
};
const onClickBenachrichtigung = (): void => {
    props.benachrichtigungGelesenUngelesen(props.benachrichtigung);
};
const formSubmit = (): void => {
    aktionForm.value?.submit();
};

if (props.benachrichtigung.aktionTyp !== null) {
    aktionUrl = `aktion.php?aktion=${props.benachrichtigung.aktionTyp}`;
}
</script>
