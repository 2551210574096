<template>
    <div>
        <div class="tw-flex tw-w-full tw-pb-1 tw-border-b tw-border-hiorg-teal-500 tw-justify-between tw-items-center">
            <div class="tw-flex tw-items-center">
                <FontAwesomeIcon
                    :icon="icon.icon"
                    class="tw-mr-2"
                    :class="icon.farbe"
                    size="lg"
                    title="Kategorie"
                />
                <span
                    class="tw-text-hiorg-teal-500 tw-font-semibold tw-text-xl"
                >
                    {{ props.kategorie }}
                </span>
            </div>
            <div class="tw-flex">
                {{ props.datum }}
            </div>
        </div>
        <div
            v-for="insight in props.insights"
            :key="insight.title"
            class="tw-mt-4"
        >
            <InsightCardContent
                :insight="insight"
                class="tw-pb-4"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import type {Insight} from "../../models/api/insight/Insight";
import InsightCardContent from "./InsightCardContent.vue";
import type {KategorieEnum} from "../../models/api/insight/KategorieEnum";
import {iconKategrieMapper} from "../../utilities/Insight/IconMapper";

const props = defineProps<{
    kategorie: KategorieEnum;
    datum: string;
    insights: Array<Insight>;
}>();

const icon = iconKategrieMapper(props.kategorie);
</script>
